<template>
  <div class="doctor-form-page">
    <app-header icon="clipboard" :title="headerTitle" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
          <form @submit.prevent v-if="!isLoading">
            <div class="row">
              <div class="col-sm-12 col-lg-6 col-xl-6">
                <div>
                  <h2 class="subtitle">Foto</h2>
                  <div></div>
                  <div
                    @click="onImageClick(doctor.photo)"
                    v-if="doctor.photo"
                    class="photo-preview"
                  >
                    <img
                      v-if="doctor.photo"
                      :src="doctor.photo"
                      @error="setDefaultPhoto('photo')"
                    />
                  </div>
                  <app-file-picker
                    label="Foto"
                    accept="image/*"
                    v-if="!doctorId"
                    v-model="doctor.photo"
                    :errors="doctor.photo"
                    @fileSelected="onPhotoSelected"
                    @fileDeleted="() => (this.doctor.photo = '')"
                  >
                  </app-file-picker>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-6">
                <h2 class="subtitle">Foto do CRM</h2>
                <div>
                  <div
                    @click="onImageClick(doctor.crm_photo)"
                    v-if="doctor.crm_photo"
                    class="photo-preview"
                  >
                    <img
                      :src="doctor.crm_photo"
                      @error="setDefaultPhoto('crm_photo')"
                    />
                  </div>
                </div>
                <div>
                  <app-file-picker
                    accept="image/*"
                    v-if="!doctorId"
                    label="Foto do CRM"
                    v-model="doctor.crm_photo"
                    @fileSelected="onCrmPhotoSelected"
                    @fileDeleted="() => (this.doctor.crm_photo = '')"
                  >
                  </app-file-picker>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit-first">
                <h2 class="subtitle">Status</h2>

                <b-checkbox
                  name="approved"
                  true-value="1"
                  false-value="0"
                  v-model="doctor.approved"
                  class="mr-4"
                  >Aprovado
                </b-checkbox>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
                <h2 class="subtitle">Informações</h2>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <app-input
                  name="name"
                  label="Nome"
                  v-model="doctor.name"
                  :errors="errors.name"
                />
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <app-input
                  v-model="doctor.cpf"
                  name="cpf"
                  label="CPF"
                  :mask="MASKS.cpf"
                  v-mask="'###.###.###-##'"
                  :errors="errors.cpf"
                />
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <app-input
                  v-if="doctor.user"
                  name="email"
                  label="Email"
                  type="email"
                  required
                  v-model="doctor.email"
                  :errors="errors.email"
                />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <b-field label="Data de nascimento">
                  <b-datepicker
                    v-model="doctor.birthday"
                    :locale="'pt-BR'"
                    :years-range="[-100, 100]"
                    placeholder="Selecionar data..."
                    icon="calendar"
                    trap-focus
                    :errors="errors.birthday"
                  ></b-datepicker>
                </b-field>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-4">
                <app-input
                  v-model="doctor.phone"
                  type="text"
                  name="phone"
                  label="Telefone fixo"
                  v-mask="'(##) ####-####'"
                  :errors="errors.phone"
                ></app-input>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-4">
                <app-input
                  v-model="doctor.cellphone"
                  type="text"
                  name="cellphone"
                  label="Celular"
                  v-mask="'(##) #####-####'"
                  :errors="errors.cellphone"
                ></app-input>
              </div>
            </div>
            <div class="row mt-2" v-if="!doctorId">
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <app-input
                  v-model="doctor.password"
                  :errors="errors.password"
                  type="password"
                  required
                  minlength="6"
                  autocomplete="new-password"
                  name="password"
                  label="Senha"
                ></app-input>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <app-input
                  v-model="doctor.password_confirmation"
                  :errors="errors.password_confirmation"
                  type="password"
                  required
                  minlength="6"
                  autocomplete="new-password"
                  name="password_confirmation"
                  label="Confirme a senha"
                ></app-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-lg-6 col-xl-4">
                <app-input
                  v-model="doctor.crm"
                  type="text"
                  name="crm"
                  label="CRM"
                  :errors="errors.crm"
                ></app-input>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <b-field label="CRM UF">
                  <b-select
                    v-model="doctor.crm_uf"
                    placeholder="Selecionar"
                    name="crm_uf"
                    :errors="errors.crm_uf"
                    expanded
                  >
                    <option
                      v-for="state in states"
                      :key="state.id"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <app-input
                  v-model="doctor.rqe"
                  type="text"
                  name="rqe"
                  label="RQE"
                  :errors="errors.rqe"
                ></app-input>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-3">
                <b-field label="Especialidade">
                  <b-dropdown
                    v-model="doctor.specialties"
                    aria-role="list"
                    scrollable
                    max-height="200"
                    expanded
                  >
                    <template #trigger>
                      <b-button icon-right="chevron-down" expanded>
                        {{ selectedSpecialty }}
                      </b-button>
                    </template>
                    <b-dropdown-item custom aria-role="listitem">
                      <b-input
                        v-model="searchSpecialty"
                        placeholder="Pesquise"
                        expanded
                      />
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="specialty in filteredSpecialty"
                      :key="specialty.id"
                      :value="specialty.id"
                      aria-role="listitem"
                    >
                      <span>{{ specialty.name }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>
              </div>
              <div class="col-3">
                <b-field
                  v-if="!subspecialties.length"
                  label="Subespecialidade"
                  required
                >
                  <b-tag type="is-dark" size="is-medium"
                    >Nenhuma subespecialidade cadastrada</b-tag
                  >
                </b-field>
                <b-field
                  v-if="subspecialties.length && doctor.specialties.length > 0"
                  label="Subespecialidade"
                  required
                >
                  <b-dropdown
                    v-if="subspecialties && doctor.specialties.length > 0"
                    v-model="doctor.subspecialties"
                    multiple
                    aria-role="list"
                  >
                    <template #trigger>
                      <b-button type="is-dark" icon-right="chevron-down">
                        {{ subspecialtiesSelecteds }}
                      </b-button>
                    </template>
                    <b-dropdown-item
                      v-for="subspecialty in subspecialties"
                      :key="subspecialty.id"
                      :value="subspecialty.id"
                      aria-role="listitem"
                    >
                      <span>{{ subspecialty.name }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <b-field label="Mini Currículo">
                  <b-input
                    v-model="doctor.mini_curriculum"
                    name="mini_curriculum"
                    type="textarea"
                    :errors="errors.mini_curriculum"
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
                <h2 class="subtitle">Endereço</h2>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-lg-6 col-xl-2">
                <app-input
                  label="CEP"
                  @input="getCepInfo"
                  v-model="doctor.cep"
                  type="text"
                  :mask="MASKS.cep"
                  v-mask="'#####-###'"
                  :errors="errors.cep"
                ></app-input>
              </div>
              <div class="col-3" style="display: flex; align-items: flex-end">
                <b-button
                  @click="getCepInfo(doctor.cep, true)"
                  icon-left="search"
                  type="is-text"
                >
                  Buscar pelo CEP
                </b-button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-lg-6 col-xl-4">
                <app-input
                  v-model="doctor.street"
                  type="text"
                  name="street"
                  label="Rua"
                ></app-input>
              </div>

              <div class="col-sm-12 col-lg-6 col-xl-2">
                <app-input
                  v-model="doctor.number"
                  type="text"
                  name="number"
                  label="Número"
                  :errors="errors.number"
                ></app-input>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-3">
                <app-input
                  v-model="doctor.complement"
                  type="text"
                  name="complement"
                  label="Complemento"
                  :errors="errors.complement"
                ></app-input>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-lg-6 col-xl-4">
                <app-input
                  v-model="doctor.neighborhood"
                  type="text"
                  name="neighborhood"
                  label="Bairro"
                  :errors="errors.neighborhood"
                ></app-input>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-2">
                <b-field label="Cidade">
                  <b-select
                    expanded
                    v-model="doctor.city.id"
                    placeholder="Selecionar"
                    :errors="errors.city_id"
                  >
                    <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="col-sm-12 col-lg-6 col-xl-4">
                <b-field label="Estado">
                  <b-select
                    v-if="doctor.city"
                    v-model="doctor.city.state_id"
                    placeholder="Selecionar"
                    @input="loadCities(doctor.city.state_id)"
                  >
                    <option
                      v-for="state in states"
                      :key="state.id"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>

            <div class="row mt-2" v-if="doctorId">
              <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
                <h2 class="subtitle">
                  Dados para geração de receitas assinadas digitalmente
                </h2>
                <b-tooltip
                  label="Caso não enviado, as receitas médicas não serão assinadas digitalmente"
                  position="is-right"
                >
                  <b-icon icon="question-circle" type="is-primary"> </b-icon>
                </b-tooltip>
              </div>
            </div>

            <div class="row mt-2" v-if="doctorId">
              <div class="col-4">
                <app-file-picker
                  label="Arquivo PFX"
                  accept=".pfx"
                  v-model="doctor.pfx_document"
                  :errors="errors.pfx_document"
                  @fileSelected="onPfxDocumentSelected"
                  @fileDeleted="() => (doctor.pfx_document = '')"
                >
                </app-file-picker>
              </div>

              <div class="col-3">
                <app-input
                  label="Senha do arquivo"
                  v-model="doctor.pfx_password"
                  :errors="errors.pfx_password"
                  type="password"
                ></app-input>
              </div>
            </div>

            <div class="card" style="margin-top: 40px">
              <div class="card-header">
                <div class="card-header-icon">
                  <b-icon icon="info-circle"></b-icon>
                </div>
                <div class="card-header-title">
                  Os dados cadastrais deste quadro são exigidos pela legislação
                  para criação de conta cobrança
                </div>
              </div>
              <div class="card-content custom-scroll">
                <div class="row mt-2">
                  <div class="col-3">
                    <app-input
                      label="Renda mensal ou receita"
                      v-model="doctor.monthly_income_or_revenue"
                      :errors="errors.monthly_income_or_revenue"
                      :mask="MASKS.money"
                      :disabled="!isEditable"
                    ></app-input>
                  </div>
                  <div class="col-3">
                    <app-input
                      label="Nome da Mãe"
                      v-model="doctor.mother_name"
                      type="text"
                      :errors="errors.mother_name"
                      :disabled="!isEditable"
                    ></app-input>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-3">
                    <app-autocomplete
                      label="Selecionar banco"
                      flag="bank"
                      ref="bankSelector"
                      v-model="doctor.bank"
                      :value="doctor.bank"
                      :getter="banksGetter"
                      :setter="banksSetter"
                      :errors="errors.bank"
                      field="name_with_number"
                    ></app-autocomplete>
                  </div>

                  <div class="col-3">
                    <app-input
                      label="Agência"
                      v-model="doctor.agency"
                      type="text"
                      :errors="errors.agency"
                    ></app-input>
                  </div>

                  <div class="col-3">
                    <app-input
                      label="Conta"
                      v-model="doctor.account"
                      type="text"
                      :errors="errors.account"
                    ></app-input>
                  </div>

                  <div v-if="showAccountComplementNumber" class="col-3">
                    <app-input
                      label="Complemento da conta"
                      v-model="doctor.accountComplementNumber"
                      type="text"
                      :errors="errors.accountComplementNumber"
                    ></app-input>
                  </div>
                  <div class="col-12">
                    <p class="alert">
                      Obs: Utilizar uma conta bancária que esteja vinculada ao
                      CPF cadastrado para o médico!
                    </p>
                  </div>
                </div>
                <div class="row mt-2" v-if="doctorId">
                  <div class="col-4">
                    <app-file-picker
                      label="Foto RG ou CNH"
                      v-model="doctor.personal_document"
                      :errors="errors.personal_document"
                      accept="image/*"
                      :disabled="isEditableJuno('ID')"
                      @fileSelected="onDocumentPhotoSelected($event, 1)"
                      @fileDeleted="() => (this.doctor.personal_document = '')"
                    >
                    </app-file-picker>

                    <div
                      v-if="doctor.personal_document"
                      class="document-preview"
                    >
                      <b-loading
                        :active.sync="documentPhotoIsLoading"
                        :is-full-page="false"
                      ></b-loading>
                      <img
                        :src="doctor.personal_document"
                        alt="Imagem RG ou CNH"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <app-file-picker
                      label="Selfie com o Documento"
                      v-model="doctor.selfie_document"
                      :errors="errors.selfie_document"
                      accept="image/*"
                      :disabled="isEditableJuno('SELFIE')"
                      @fileSelected="onDocumentPhotoSelected($event, 2)"
                      @fileDeleted="() => (this.doctor.selfie_document = '')"
                    >
                    </app-file-picker>

                    <div v-if="doctor.selfie_document" class="document-preview">
                      <b-loading
                        :active.sync="selfieDocumentIsLoading"
                        :is-full-page="false"
                      ></b-loading>
                      <img
                        :src="doctor.selfie_document"
                        alt="Selfie com RG ou CNH"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-5">
              <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit my-5">
                <h2 class="subtitle">Financeiro</h2>
              </div>
              <div class="col-sm-12 col-lg-4 col-xl-3">
                <app-input
                  name="value"
                  required
                  label="Saldo"
                  :value="fixedValue('balance')"
                  disabled
                />
              </div>
              <div class="col-sm-12 col-lg-4 col-xl-3">
                <app-input
                  name="value"
                  required
                  label="Saldo a liberar"
                  :value="fixedValue('withheld')"
                  disabled
                />
              </div>
              <div class="col-sm-12 col-lg-4 col-xl-3">
                <app-input
                  name="value"
                  required
                  label="Saldo transferível"
                  :value="fixedValue('transferable')"
                  disabled
                />
              </div>
            </div>
            <div class="col-6">
              <b-table
                @page-change="onPageChange"
                :per-page="perPage"
                :loading="is_loading_transactions"
                :total="total"
                :data="transactions_data"
                paginated
                backend-pagination
                aria-page-label="Página"
                aria-next-label="Próxima Página"
                aria-current-label="Página Atual"
                aria-previous-label="Página Anterior"
              >
                <b-table-column v-slot="props" field="amount" label="Valor">
                  {{ '$' + parseFloat(props.row.amount).toFixed(2) }}
                </b-table-column>

                <b-table-column
                  v-slot="props"
                  field="date"
                  label="Data/Horário"
                >
                  {{ props.row.created_at | date('DD/MM/YYYY [às] HH:mm') }}
                </b-table-column>

                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="frown" size="is-large"></b-icon>
                      </p>
                      <p>Nenhuma transferência encontrada.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>

            <div class="row mt-4">
              <div class="col-12 button-container">
                <b-button @click="onSubmit()" type="is-primary"
                  >Salvar</b-button
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CepService from '@/services/cep.service';
import StatesService from '@/services/states.service';
import CitiesService from '@/services/cities.service';
import DoctorsService from '@/services/doctors.service.js';
import SpecialtiesService from '@/services/specialties.service';
import SubspecialtiesService from '@/services/subspecialties.service';

import FileMixin from '@/mixins/FileMixin';
import MASKS from '@/constants/masks.constant';
import BanksService from '@/services/banks.service';
import Autocomplete from '@/components/inputs/Autocomplete.vue';
import DOCUMENTS_STATUS from '@/constants/document_juno_status.constant';

import * as moment from 'moment';

export default {
  mixins: [FileMixin],
  components: {
    'app-autocomplete': Autocomplete,
  },
  computed: {
    filteredSpecialty() {
      return this.specialties.filter(
        (specialty) =>
          specialty.name
            .toLowerCase()
            .indexOf(this.searchSpecialty.toLowerCase()) >= 0
      );
    },
    selectedSpecialty: function () {
      let selectedSpecialty = this.specialties.find(
        (specialty) => specialty.id === this.doctor.specialties
      );

      if (selectedSpecialty == null) {
        return 'Adicione sua especialidade';
      }

      return selectedSpecialty.name;
    },
    headerTitle() {
      return this.doctorId
        ? 'Editar informações do médico'
        : 'Cadastrar novo médico';
    },
    subspecialtiesSelecteds: function () {
      let result = [];
      let me = this;
      this.subspecialties.forEach(function (subspecialty) {
        if (
          me.doctor.subspecialties &&
          me.doctor.subspecialties.indexOf(subspecialty.id) > -1
        ) {
          result.push(subspecialty.name);
        }
      });
      if (result.length <= 0) {
        return 'Selecione as subspecialidades';
      }
      return result.join(', ');
    },
    isEditable() {
      return !!this.doctor.second_stage;
    },
  },
  watch: {
    perPage() {
      if (this.perPage > this.transactions_data.length) this.loadPatients();
    },
    'doctor.specialties'() {
      if (this.doctor.specialties[0]) {
        SubspecialtiesService.getAll({
          specialty_id: this.doctor.specialties[0],
        }).then(({ data }) => (this.subspecialties = data));
      } else {
        if (this.doctor.specialties.length == 0) {
          this.doctor.subspecialties = [];
        }
      }
    },
    'doctor.bank'() {
      if (this.doctor.bank === 104) this.showAccountComplementNumber = true;
      else this.showAccountComplementNumber = false;
    },
  },
  data: () => ({
    MASKS: MASKS,
    doctorId: null,
    isLoading: false,
    is_loading_transactions: false,
    transactions_data: [],
    total: 0,
    page: 1,
    perPage: 10,
    balance_info: {
      balance: null,
      withheld: null,
      transferable: null,
    },
    errors: {},
    isLoadingCity: false,
    states: [],
    cities: [],
    countLoad: 0,
    searchSpecialty: '',
    doctorSpecialties: [],
    doctorSubspecialties: [],
    specialties: [],
    subspecialties: [],
    photoSelected: false,
    crmPhotoSelected: false,
    showAccountComplementNumber: false,
    documentPhotoIsLoading: false,
    selfieDocumentIsLoading: false,
    crmPhotoIsLoading: false,
    isCreate: false,
    doctor: { rqe: null },
  }),
  methods: {
    statesGetter: StatesService.search,
    citiesGetter: CitiesService.search,
    banksGetter: BanksService.search,
    banksSetter: BanksService.getBank,
    onPageChange(page) {
      this.page = page;
      this.loadDoctorTransactions();
    },
    loadDoctorTransactions() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        doctor_id: this.doctorId,
      };
      this.is_loading_transactions = true;

      DoctorsService.getDoctorTransactions({ ...params })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          this.total = total;
          this.page = current_page;
          this.transactions_data = Object.values(items);
        })
        .finally(() => (this.is_loading_transactions = false));
    },
    fixedValue(key) {
      return this.balance_info[key]
        ? this.balance_info[key].toFixed(2)
        : (0).toFixed(2);
    },
    loadDoctor() {
      this.isLoading = true;
      DoctorsService.getId(this.doctorId)
        .then(({ data }) => {
          this.doctor = {
            ...data,
            email: data.user.email,
            city: {
              name: !data.city ? '' : data.city.name,
            },
            birthday: data.birthday ? new Date(data.birthday) : null,
            specialties: data.specialties.map((specialty) => specialty.id)[0],
            subspecialties: data.subspecialties.map(
              (subspecialty) => subspecialty.id
            ),
            pfx_document: data.doctor_certificate
              ? data.doctor_certificate.certificate_path.split('/').pop()
              : '',
            monthly_income_or_revenue: Number(
              data.monthly_income_or_revenue
            ).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          };

          this.loadCity(this.doctor.city_id);
        })
        .catch(() =>
          this.$buefy.snackbar.open({
            message: 'Erro ao carregar dados do médico',
          })
        )
        .finally(() => {
          this.isLoading = false;

          const promises = [this.loadStates(), this.loadSpecialties()];

          Promise.all(promises).catch(() =>
            this.$buefy.snackbar.open({
              message: 'Um erro inesperado aconteceu!',
              type: 'is-danger',
            })
          );
        });
      DoctorsService.getBalanceInfo(this.doctorId).then(({ data }) => {
        this.balance_info = { ...data };
      });
    },
    setDefaultPhoto(key) {
      this.doctor[key] = require('@/assets/img/user.png');
    },

    getSubspecialties() {
      return this.doctor.subspecialties.map((sub) => sub.name);
    },
    async getCepInfo(cep, onClick = false) {
      if (typeof cep == 'undefined' || cep == null) {
        return;
      }

      if (!!this.doctorId && !onClick) return;

      cep = cep.replace(/\D/g, '');
      if (!cep || cep.length < 8) return;

      await CepService.getCepInfo(cep).then(({ data }) => {
        if (!data.city_id) {
          return;
        }
        this.loadCity(data.city_id);
        this.doctor = {
          ...this.doctor,
          street: data.logradouro,
          neighborhood: data.bairro,
        };
      });
    },
    loadCity(cityId) {
      CitiesService.getId(cityId)
        .then(({ data }) => {
          let city = {
            id: '',
            state_id: '',
          };
          if (
            typeof data.id != 'undefined' &&
            typeof data.state_id != 'undefined'
          ) {
            city = data;
            this.loadCities(city.state_id);
            this.doctor = {
              ...this.doctor,
              city: {
                id: city.id,
                state_id: city.state_id,
              },
            };
          }
        })
        .finally(() => (this.isLoading = false));
    },
    loadCities(state_id) {
      this.isLoadingCity = true;
      CitiesService.searchByStateId(state_id)
        .then(({ data }) => (this.cities = data))
        .finally(() => (this.isLoading = false));
    },
    loadStates() {
      this.isLoading = true;
      StatesService.get()
        .then(({ data }) => (this.states = data))
        .finally(() => (this.isLoading = false));
    },
    onImageClick(url) {
      if (!url) return;
      window.open(url, '_blank');
    },
    onSubmit() {
      this.errors = {};

      !this.doctorId && this.save();
      this.doctorId && this.update(this.doctorId);
    },
    async update(id) {
      if (this.isCreate) {
        delete this.doctor.photo;
        delete this.doctor.crm_photo;
      }

      let data = {
        ...this.doctor,
        cpf: this.doctor.cpf?.replace(/\D/g, ''),
        crm: this.doctor.crm?.replace(/\D/g, ''),
        cep: this.doctor.cep?.replace(/\D/g, ''),
        birthday:
          this.doctor.birthday != null
            ? moment(this.doctor.birthday).format('YYYY-MM-DD')
            : '',
        monthly_income_or_revenue: this.unmask(
          this.doctor.monthly_income_or_revenue
        ),
      };

      this.isSaving = true;
      DoctorsService.update(id, data)
        .then(() => {
          this.isCreate = false;
          this.$buefy.snackbar.open('Médico atualizado com sucesso!');
          this.$router.push({ name: 'doctors.index' });
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Médico.',
            'error'
          );
          this.errors = response.data.message.errors;
        })
        .finally(() => (this.isSaving = false));
    },
    async save() {
      let data = {
        ...this.doctor,
        approved: this.doctor.approved,
        name: this.doctor.name,
        cpf: this.doctor.cpf?.replace(/\D/g, ''),
        email: this.doctor.user.email,
        password: this.doctor.user.password,
        password_confirmation: this.doctor.user.password_confirmation,
        birthday:
          this.doctor.birthday != null
            ? moment(this.doctor.birthday).format('YYYY-MM-DD')
            : '',
        phone: this.doctor.phone,
        cellphone: this.doctor.cellphone,
        mini_curriculum: this.doctor.mini_curriculum,
        rqe: this.doctor.rqe,
        crm: this.doctor.crm?.replace(/\D/g, ''),
        crm_uf: this.doctor.crm_uf,
        cep: this.doctor.cep?.replace(/\D/g, ''),
        street: this.doctor.street,
        number: this.doctor.number,
        complement: this.doctor.complement,
        neighborhood: this.doctor.neighborhood,
        city_id: this.doctor.city.id,
        specialties: this.doctor.specialties,
        subspecialties: this.doctor.subspecialties,
        monthly_income_or_revenue: this.unmask(
          this.doctor.monthly_income_or_revenue
        ),
      };

      this.isSaving = true;
      await DoctorsService.save(data)
        .then(({ data }) => {
          this.isCreate = true;
          this.update(data.data.id);
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Médico.',
            'error'
          );
          this.errors = response.data.errors;
        })
        .finally(() => (this.isSaving = false));
    },
    loadSpecialties() {
      SpecialtiesService.getAll().then(({ data }) => {
        this.specialties = data;
      });
    },
    onCrmPhotoSelected(value) {
      if (this.id) {
        this.doctor.crm_photo = URL.createObjectURL(value);
        DoctorsService.setPhoto(this.id, value, 2)
          .then(({ data }) => (this.doctor.crm_photo = data.url))
          .finally(() => {
            this.crmPhotoIsLoading = false;
          });
      } else {
        this.fileToBase64(value).then(
          (base64) => (this.doctor.crm_photo = base64)
        );
      }
      this.crmPhotoSelected = true;
    },
    onPhotoSelected(value) {
      if (this.id) {
        this.doctor.photo = URL.createObjectURL(value);
        DoctorsService.setPhoto(this.id, value, 2)
          .then(({ data }) => (this.doctor.photo = data.url))
          .finally(() => {
            this.crmPhotoIsLoading = false;
          });
      } else {
        this.fileToBase64(value).then((base64) => (this.doctor.photo = base64));
      }
      this.photoSelected = true;
    },
    onPfxDocumentSelected(file) {
      DoctorsService.setPfxDocument(this.doctorId, file).then(
        ({ data }) => (this.doctor.pfx_document = data)
      );
    },
    isEditableJuno(TYPE) {
      if (!this.doctor.juno_documents_status.status) return false;

      const doc = this.doctor.juno_documents_status.filter(
        (document) => document.type == TYPE
      )[0];

      return DOCUMENTS_STATUS[doc.status] !== DOCUMENTS_STATUS.REJECTED;
    },
    onDocumentPhotoSelected(value, type) {
      if (type === 1) {
        this.documentPhotoIsLoading = true;
        this.doctor.personal_document = URL.createObjectURL(value);
      } else {
        this.selfieDocumentIsLoading = true;
        this.doctor.selfie_document = URL.createObjectURL(value);
      }

      DoctorsService.setPhoto(this.id, value, type, 1).finally(() => {
        this.documentPhotoIsLoading = false;
        this.selfieDocumentIsLoading = false;
      });
    },
    unmask(value) {
      if (!value) return value;

      const realValue = value.split('R$').pop();
      return realValue.trim().replaceAll('.', '').replace(',', '.');
    },
  },
  mounted() {
    this.isLoading = true;
    this.doctorId = this.$route.params.id;

    if (this.doctorId) this.doctorId && this.loadDoctor();
    else this.isLoading = false;

    this.loadDoctorTransactions();
  },
};
</script>

<style lang="scss" scoped>
.doctor-form-page {
  .photo-preview {
    overflow: hidden;

    width: 450px;
    height: 250px;

    border: 3px solid #f7f5f5;
    border-radius: 8px;

    cursor: pointer;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .alert {
    margin-top: 10px;
    font-style: italic;
    color: var(--color-primary);
  }

  .subtitle-edit {
    display: flex;

    span {
      margin-top: 1px;
      margin-left: 5px;
    }
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.subspecialties {
  .tag {
    margin-right: 10px !important;
  }
}

.doctor {
  display: flex;

  &__image {
    border-radius: 12px;
    margin-right: 24px;

    text-align: center;

    cursor: pointer;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .doctor-photo {
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .links {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
</style>
