export default {
  cpf: {
    delimiters: ['.', '.', '-'],
    blocks: [3, 3, 3, 2],
    numericOnly: true,
  },
  cep: {
    delimiters: ['.', '-'],
    blocks: [2, 3, 3],
    numericOnly: true,
  },
  cellphone: {
    delimiters: ['(', ')', ' ', '-'],
    blocks: [0, 2, 0, 5, 4],
    numericOnly: true,
  },
  phone: {
    delimiters: ['(', ')', ' ', '-'],
    blocks: [0, 2, 0, 4, 4],
    numericOnly: true,
  },
  money: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalMark: ',',
    delimiter: '.',
    prefix: 'R$ ',
  },
};
